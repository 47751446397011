import React from 'react';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import PinterestIcon from '@material-ui/icons/Pinterest';

export const Footer = () => {
  return (
    <div>
      <div className="general-footer-container">
        <div className="footer-buttons-header">
          <h1 className="footer-header">Pelle</h1>
          <div className="footer-buttons-container">
            <button className="button-sign-footer">Join a Gathering</button>
            <a href={`/gatherings/new`}>
              <button className="button-create-footer">
                Create a Gathering
              </button>
            </a>
          </div>
        </div>
        <hr className="footer-division"></hr>
        <div className="footer-paragraph">
          <div className="footer-slogan-social-media">
            <p>© Pelle 2020. We love our planet!</p>
            <div className="footer-social-media-container">
              <p className="footer-followus">Follow us:</p>
              <FacebookIcon className="footer-social-media" />
              <InstagramIcon className="footer-social-media" />
              <PinterestIcon className="footer-social-media" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
