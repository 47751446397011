import React from "react";
import axios from "axios";
import Avatar from "@material-ui/core/Avatar";
import { CardTemplate } from "../components/Card/Card";
import { Footer } from "./Footer/Footer";
import { Navbar } from "./Navbar/Navbar";

class User extends React.Component {
  constructor(props) {
    console.log("props-->", props);
    super(props);
    this.state = {
      user: [],
      url: [],
      pictures: [],
      attendees: [],
      facebookPicture: [],
      googlePicture: [],
      currentUser: props.current,
    };
  }

  componentDidMount() {
    axios
      .get(`/api/v1/all/attendees`)
      .then((response) => this.setState({ attendees: response.data }))
      .catch((error) => console.log(error));

    axios
      .get(`/api/v1/users/${this.props.user}/profile`)
      .then((response) => {
        console.log(response);
        this.setState({
          user: response.data,
          url: response.data.photo.url,
          facebookPicture: response.data.facebook_picture_url,
          googlePicture: response.data.google_url,
          date: response.data.created_at.slice(0, 10),
        });
      })
      .catch((error) => console.log(error));
    this.setState({ pictures: this.props.pictures });
  }

  render() {
    const { user, url, pictures, facebookPicture, googlePicture, currentUser } =
      this.state;
    const { name, location, bio, created_at } = user;

    const today = new Date();
    const userJoined = new Date(created_at);
    const getDays = Math.round((today - userJoined) / (1000 * 60 * 60 * 24));

    let user_picture = null;

    if (url) {
      user_picture = <Avatar className="user-avatar" src={url} />;
    } else if (facebookPicture) {
      user_picture = <Avatar className="user-avatar" src={facebookPicture} />;
    } else if (googlePicture) {
      user_picture = <Avatar className="user-avatar" src={googlePicture} />;
    } else {
      user_picture = <Avatar className="user-avatar" />;
    }

    const { meetups } = this.props;
    const { attendees } = this.state;
    const userMeetups = meetups.map((meetup, index) => {
      return attendees.filter((attendee) => attendee.meetup_id === meetup.id);
    });

    const userAttendedCount = attendees.filter(
      (attendee) => parseInt(attendee.user_id) === user.id
    ).length;

    setInterval(() => {
      const volunteerAnimation = document.getElementById("user-animation");
      if (volunteerAnimation) {
        volunteerAnimation.style.transform = `translateX(${
          Math.random() * 100
        }px)`;
      }
    }, 1000);

    const styles = {
      backgroundImage: 'url("/assets/create_gathering.jpg")',
    };
    return (
      <div style={styles}>
        <Navbar user={currentUser} />
        <div className="user-profile-container">
          <div className="user-wrapper">
            <div className="user-information-container">
              <div>
                <div className="user-picture-container">{user_picture}</div>
                <h1 className="user-profile-name">{name}</h1>
                {location && (
                  <p className="user-profile-location">{location}</p>
                )}
                <div className={"user-recycle-container"}></div>
              </div>
            </div>
            <div className="user-data">
              <img src="/assets/planet-earth.png" alt="plant-image" />
              <div className="user-data-attended" data-user="attended">
                {userMeetups.length}
                <p className="user-data-joined-text">Gatherings created</p>
              </div>
              <div className="user-data-hosted" data-user="hosted">
                {userAttendedCount}
                <p className="user-data-joined-text">Gatherings Attended</p>
              </div>
              <div className="user-data-joined" data-user="joined">
                <div>{getDays}</div>
                <p className="user-data-joined-text">Active days</p>
              </div>
            </div>
            <div className="user-profile-biography">
              <div className="user-main-title">
                <h1>Bio</h1>
                <hr className="user-red-divider"></hr>
              </div>
              <div className={"user-recycle-container"} id="user-animation">
                <img
                  className={"user-profile-recycle"}
                  src={"/assets/volunteer.png"}
                  alt="recycle-bin"
                />
              </div>
              {!!bio?.length && (
                <div>
                  <div className="user-biography">{bio}</div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="footer-container">
          <Footer />
        </div>
      </div>
    );
  }
}

export default User;
