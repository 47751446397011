import React from "react";
import { Footer } from "../Footer/Footer";

const MainMobile = ({
  users,
  attendees,
  allGatherings,
  mobileCards: cards,
  meetups,
  pickedWaste,
}) => {
  return (
    <div className="mobile-homepage-container">
      <section className="child-home page1">
        <div id="hero">
          <video
            id="home-video"
            loop
            muted
            playsInline
            autoPlay
            src={"/assets/video.mp4"}
            preload={"auto"}
            type={"video/mp4"}
          />
        </div>
        <div className="header-home">
          <h1 id="logo">Pelle</h1>
          <p id="home-slogan">Gather with a purpose.</p>
        </div>
        <div className="mobile-home-banner-text">
          <h1>How it Works</h1>
          <hr className="red-divider"></hr>
        </div>
      </section>
      <div className="mobile-home-information"></div>
      <div className="cards-container">
        <div className="home-card">
          <img className="home-card-image" src="/assets/climate.png" />
          <div className="home-card-title">
            <h2>Every piece matters</h2>
          </div>

          <div className="home-card-description">
            <p>Join the Pelle community in making the world a cleaner place.</p>
          </div>
        </div>

        <div className="home-card">
          <img className="home-card-image" src="/assets/group.png" />
          <div className="home-card-title">
            <h2>Connect with others to create an impact</h2>
          </div>

          <div className="home-card-description">
            <p>
              Join a gathering or start one with your family, community or team.
            </p>
          </div>
        </div>

        <div className="home-card">
          <img className="home-card-image" src="/assets/cleaning.png" />
          <div className="home-card-title">
            <h2>Welcome to the Pelle community</h2>
          </div>
          <div className="home-card-description">
            <p>You can make a difference. Together we can create an impact.</p>
          </div>
        </div>
      </div>
      <div className="mobile-numbers-statistics-container">
        <div className="mobile-numbers-cont">
          <div className="circle mini-circle"></div>
          <div className="circle no-circle"></div>
          <div className="mobile-animation-container">
            <div className="mobile-attendees-users">
              <p className="bubble-words big-num">{users}</p>
              <p className="bubble-words">USERS</p>
            </div>

            <div className="mobile-attendees-circle">
              <p className="bubble-words big-num">{attendees.length}</p>
              <p className="bubble-words">ATTENDEES</p>
            </div>
          </div>

          <div className="mobile-animation-container">
            <div className="mobile-gatherings-circle">
              <p className="bubble-words big-num">{allGatherings.length}</p>
              <p className="bubble-words">GATHERINGS</p>
            </div>

            <div className="mobile-gatherings-waste">
              <p className="bubble-words big-num">{pickedWaste}</p>
              <p className="bubble-words">WASTE</p>
            </div>
          </div>
        </div>
      </div>
      <div className="mobile-gathering-cards-container">{cards}</div>
      <img src="/assets/plant.jpg" id="vondelpark-bkg" alt="" />
      <div className="mobile-footer-container">
        <Footer />
      </div>
    </div>
  );
};

export default MainMobile;
