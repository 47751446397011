import React from "react";
import { Footer } from "../Footer/Footer";

const MainDesktop = ({
  users,
  attendees,
  allGatherings,
  desktopCards: cards,
  meetups,
  pickedWaste,
}) => {
  return (
    <React.Fragment>
      <section className="child-home page1">
        <div id="hero">
          <video
            id="home-video"
            loop
            muted
            playsInline
            autoPlay
            src={"/assets/video.mp4"}
            preload={"auto"}
            type={"video/mp4"}
          />
          <div className="triangle"></div>
        </div>
        <div className="header-home">
          <h1 id="logo">Pelle</h1>
          <p id="home-slogan">Gather with a purpose.</p>
        </div>
      </section>
      {/* Page Two on Home page */}{" "}
      <section className="child-home page2">
        <div className="how-it-works">
          <div className="hiw-container">
            <div className="main-title" id="hiw-title">
              <h1>How it Works</h1>
              <hr className="red-divider"></hr>
            </div>
            <div className="cards-container">
              <div className="home-card">
                <img className="home-card-image" src="/assets/climate.png" />
                <div className="home-card-title">
                  <h2>Every piece matters</h2>
                </div>

                <div className="home-card-description">
                  <p>
                    Join the Pelle community in making the world a cleaner
                    place.
                  </p>
                </div>
              </div>

              <div className="home-card">
                <img className="home-card-image" src="/assets/group.png" />
                <div className="home-card-title">
                  <h2>Connect with others to create an impact</h2>
                </div>

                <div className="home-card-description">
                  <p>
                    Join a gathering or start one with your family, community or
                    team.
                  </p>
                </div>
              </div>

              <div className="home-card">
                <img className="home-card-image" src="/assets/cleaning.png" />
                <div className="home-card-title">
                  <h2>Welcome to the Pelle community</h2>
                </div>
                <div className="home-card-description">
                  <p>
                    You can make a difference. Together we can create an impact.
                  </p>
                </div>
              </div>
            </div>
            <div className="homepage-numbers">
              <div className="numbers-cont">
                <div className="circle mini-circle"></div>
                <div className="circle no-circle"></div>

                <div className="circle user-circle">
                  <p className="bubble-words small-num">{users}</p>
                  <p className="bubble-words">USERS</p>
                </div>

                <div className="circle attendee-circle">
                  <p className="bubble-words big-num">{attendees.length}</p>
                  <p className="bubble-words">ATTENDEES</p>
                </div>

                <div className="circle gatherings-circle">
                  <p className="bubble-words big-num">{allGatherings.length}</p>
                  <p className="bubble-words">GATHERINGS</p>
                </div>

                <div className="circle waste-circle">
                  <p className="bubble-words big-num">{pickedWaste}</p>
                  <p className="bubble-words">WASTE</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="child-home page3">
        <div className="gatherings">
          <div
            className="gatherings-container"
            style={
              window.innerWidth <= 1200
                ? { height: "5vw" }
                : {
                    height: meetups.length <= 0 ? "10vw" : 3 ? "50vw" : "auto",
                  }
            }
          >
            <div className="main-title gatherings-title">
              <h1>Gatherings</h1>
              <hr className="red-divider divider-left"></hr>
            </div>
            <div className="gatherings-cards-container">
              <div className="gathering-card">{cards}</div>
            </div>
          </div>
          <div className="all-gatherings-button">
            <a href={`/all`}>
              <button className="button-see-gatherings">See All</button>
            </a>
          </div>
        </div>
        <img src="/assets/plant.jpg" id="vondelpark-bkg" alt="" />
        <div className="footer-container-homepage">
          <Footer />
          <div className="waves-container">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
              <path
                fill="#165250"
                fill-opacity="1"
                d="M0,96L11.4,128C22.9,160,46,224,69,245.3C91.4,267,114,245,137,202.7C160,160,183,96,206,74.7C228.6,53,251,75,274,122.7C297.1,171,320,245,343,266.7C365.7,288,389,256,411,229.3C434.3,203,457,181,480,160C502.9,139,526,117,549,128C571.4,139,594,181,617,181.3C640,181,663,139,686,112C708.6,85,731,75,754,69.3C777.1,64,800,64,823,74.7C845.7,85,869,107,891,138.7C914.3,171,937,213,960,240C982.9,267,1006,277,1029,282.7C1051.4,288,1074,288,1097,261.3C1120,235,1143,181,1166,149.3C1188.6,117,1211,107,1234,117.3C1257.1,128,1280,160,1303,149.3C1325.7,139,1349,85,1371,58.7C1394.3,32,1417,32,1429,32L1440,32L1440,320L1428.6,320C1417.1,320,1394,320,1371,320C1348.6,320,1326,320,1303,320C1280,320,1257,320,1234,320C1211.4,320,1189,320,1166,320C1142.9,320,1120,320,1097,320C1074.3,320,1051,320,1029,320C1005.7,320,983,320,960,320C937.1,320,914,320,891,320C868.6,320,846,320,823,320C800,320,777,320,754,320C731.4,320,709,320,686,320C662.9,320,640,320,617,320C594.3,320,571,320,549,320C525.7,320,503,320,480,320C457.1,320,434,320,411,320C388.6,320,366,320,343,320C320,320,297,320,274,320C251.4,320,229,320,206,320C182.9,320,160,320,137,320C114.3,320,91,320,69,320C45.7,320,23,320,11,320L0,320Z"
              ></path>
            </svg>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default MainDesktop;
